import React from "react"
import styles from './404.module.css'
import { Link } from "@reach/router"

const NotFoundPage = () => (
  <section className={styles.section}>
    <div className="container">
      <div className="row">
        <div className="columns col-xs-12">
          <h1>Not found</h1>
          <p>You just hit a route that doesn&#39;t exist...</p>

          <Link className="button" to="/">Go to Home page</Link>
        </div>
      </div>
    </div>
  </section>
)

export default NotFoundPage
